<template>
    <Spinner v-if="loading"></Spinner>
    <PrintFile v-else :files="[file]"></PrintFile>
</template>

<script>
import PrintFile from '~/components/print/PrintFile.vue'

export default {
    components: { PrintFile },

    data: () => ({ file: {}, loading: true }),

    async mounted() {
        try {
            const { data } = await this.$axios.get(`share/${this.$route.params.token}`)

            this.file = data
            document.title = this.file.scan_code
        } catch {
            window.toast(this.$root.$t('main.oops'), 'error')
        }

        this.loading = false
    },
}
</script>
