<template>
    <form @submit.prevent="submit()">
        <div class="form-group">
            <h2>{{ $t('auth.reset_password') }}</h2>
        </div>

        <Errors :errors="errors" />

        <div class="form-group" :class="{ 'has-error': v$.email.$error }">
            <label class="form-label" for="email">{{ $t('users.email') }}</label>
            <input v-model="email" id="email" class="form-input" readonly />

            <div v-if="v$.email.$error" class="form-input-hint">{{ $t('auth.email_address_must_be_valid') }}</div>
        </div>

        <PasswordInput v-model="password" label></PasswordInput>

        <div class="form-group py-1">
            <button type="submit" class="btn btn-primary" :class="{ loading }">{{ $t('auth.reset_password') }}</button>
        </div>
    </form>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
import PasswordInput from '~/composition/PasswordInput.vue'

export default {
    setup: () => ({ v$: useVuelidate() }),

    components: { PasswordInput },

    data() {
        return {
            errors: null,
            loading: false,

            password: '',
            email: this.$route.query.email,
            token: this.$route.params.token,
        }
    },

    validations() {
        return {
            email: { email, required },
        }
    },

    methods: {
        async submit() {
            if (!await this.v$.$validate()) return

            this.errors = null
            this.loading = true

            this.$axios
                .post('auth/password/reset', {
                    token: this.token,
                    email: this.email,
                    password: this.password,
                })
                .then(() => {
                    window.toast(this.$root.$t('i.update', { i: this.$root.$t('users.password') }))

                    this.$axios
                        .post('auth/login', {
                            email: this.email,
                            password: this.password,
                        })
                        .then(({ data: jwt }) => {
                            this.$store.dispatch('startSession', jwt)
                            this.$router.push({ name: 'dashboard' })
                        })
                        .catch(({ response }) => {
                            this.loading = false
                            this.errors = response
                        })
                })
                .catch(({ response }) => {
                    this.errors = response
                    this.loading = false
                })
        },
    },
}
</script>
