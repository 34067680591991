<template>
    <SidePanel :title="$t('context.create_users')" @close="$router.push({ name: 'settings.users' })">
        <div class="form-group">
            <Errors :errors="errors" />
        </div>

        <div class="form-group">
            <label class="form-label" for="location">{{ $t('main.location') }}</label>
            <select v-model="user.location_id" id="location" class="form-select">
                <option v-for="location in locations" :key="location.id" :value="location.id">
                    {{ location.location }}
                </option>
            </select>
        </div>

        <div class="form-group">
            <label class="form-label" for="name">{{ $t('users.name') }}</label>
            <input v-model="user.name" class="form-input" id="name" :placeholder="$t('users.name')" />
        </div>

        <div class="form-group">
            <label class="form-label" for="email">{{ $t('users.email') }}</label>
            <input v-model="user.email" type="email" class="form-input" id="email" :placeholder="$t('users.email')" />
        </div>

        <div class="form-group">
            <label class="form-label" for="role">{{ $t('users.role') }}</label>
            <select v-model="user.role" id="role" class="form-select">
                <option v-for="role in roles" :key="role" :value="role">{{ $t(`user_roles.${role}`) }}</option>
            </select>
        </div>

        <div class="flex-space">
            <SaveButton @click="createUser()">{{ $t('main.invite') }}</SaveButton>
        </div>
    </SidePanel>
</template>

<script>
import SidePanel from '~/components/SidePanel.vue'

export default {
    components: { SidePanel },

    props: ['locations'],

    data() {
        return {
            user: {
                name: '',
                email: '',
                role: 'User',
                location_id: this.$store.state.user.location_id,
            },

            errors: null,
            roles: ['ReadOnly', 'User', 'Manager', 'SuperAdmin'],
        }
    },

    mounted() {
        if (
            this.$store.state.hasAllAbilities ||
            this.$store.state.user.role === 'PlatformManager' ||
            this.$store.state.user.role === 'LicenseAdmin'
        ) {
            this.roles.push('LicenseAdmin')
        }
        if (this.$store.state.user.properties.use_kiosks) {
            this.roles.push('Kiosk')
        }
    },

    methods: {
        async createUser() {
            this.errors = null

            try {
                const { data } = await this.$axios.post('users', this.user)
                this.$emit('refresh')
                this.$router.push({ name: 'settings.users.show', params: { id: data.id } })
                window.toast(this.$root.$t('i.create', { i: this.user.name }))
            } catch ({ response }) {
                this.errors = response
            }
        },
    },
}
</script>
