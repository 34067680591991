<template>
    <SidePanel :title="$t('context.create_teams')" @close="$router.push({ name: 'settings.teams' })">
        <div class="form-group">
            <Errors :errors="errors" />
        </div>

        <div class="form-group">
            <label class="form-label" for="name">{{ $t('main.name') }}</label>
            <input id="name" class="form-input" v-model="location.location" :placeholder="$t('main.name')" />
        </div>

        <div class="form-group">
            <label class="form-label" for="language">{{ $t('main.language') }}</label>
            <select v-model="location.lang" id="language" class="form-select">
                <option v-for="(lang, key) in locales" :value="key">{{ lang }}</option>
            </select>
        </div>

        <div class="form-group">
            <SaveButton @click="createLocation()">{{ $t('main.create') }}</SaveButton>
        </div>
    </SidePanel>
</template>

<script>
import SidePanel from '~/components/SidePanel.vue'

export default {
    components: { SidePanel },

    data() {
        return {
            errors: null,
            locales: window.config.locales,
            location: { lang: this.$store.state.language },
        }
    },

    methods: {
        async createLocation() {
            this.errors = null

            try {
                const { data } = await this.$axios.post('locations', this.location)

                this.$emit('refresh')
                this.$router.push({ name: 'settings.teams.show', params: { id: data.id } })
                window.toast(this.$root.$t('i.create', { i: this.location.location }))

            } catch ({ response }) {
                this.errors = response
            }
        },
    },
}
</script>
