<template>
    <WatchForUnsavedChanges ref="watch" v-model="submit" v-slot="{ hasChanges }">
        <Modal v-if="submit.id" :title="$t('timeline.form', { title: submit.form.title })" @close="back()">
            <form @submit.prevent="save()" ref="form">
                <FormFieldsModel v-for="field in submit.form.fields" v-model="field.value" :field="field"
                    :label="field.label" :required="field.required" class="form-group" bold>
                </FormFieldsModel>

                <MoveToFile v-if="canMoveBetweenFiles" :item="submit"></MoveToFile>

                <input type="submit" hidden />
            </form>

            <template #footer>
                <button class="btn btn-primary" :class="{ loading }" :disabled="!hasChanges"
                    @click="$refs.form.requestSubmit()">
                    {{ $t('main.save') }}
                </button>
            </template>
        </Modal>
        <Spinner v-else></Spinner>
    </WatchForUnsavedChanges>
</template>

<script>
import MoveToFile from '~/components/timeline/MoveToFile.vue'
import FormFieldsModel from '~/components/files/FileFormFieldsModel.vue'
import { getFormFieldAnswer } from '~/helpers/timeline.js'

export default {
    props: { file: Object },
    emits: ['refresh'],

    components: { MoveToFile, FormFieldsModel },

    data() {
        return {
            submit: {},
            loading: false,
            submit_id: this.$route.params.submit_id,
            canMoveBetweenFiles: this.$store.getters.can('Update form submits'),
        }
    },

    async mounted() {
        const { data: submit } = await this.$axios.get(`submits/${this.submit_id}`)

        for (let i = 0; i < submit.form.fields.length; i++) {
            const field = submit.form.fields[i]
            field.value = getFormFieldAnswer(field, submit.answers)
        }

        this.submit = submit
    },

    methods: {
        back() {
            if (this.$route.name === 'form_submit.show') {
                this.$router.push({ name: 'forms.show' })
            }

            if (this.$route.name === 'files.form_submit.edit') {
                this.$router.push({ name: 'files.show' })
            }
        },

        async save() {
            this.loading = true

            await this.$axios.patch(`submits/${this.submit.id}`, this.submit)

            window.toast(this.$root.$t('i.update', { i: this.$root.$t('timeline.form', { title: this.submit.form.title }) }))
            this.$refs.watch?.init()

            const moveToFile = this.submit.moveToFile

            if (moveToFile) {
                await this.$axios.post(`submits/${this.submit.id}/move/${moveToFile.id}`)

                this.$router.push({ name: 'files.show', params: { id: moveToFile.id } })
            } else {
                this.back()
                this.$emit('refresh')
            }
        },
    },
}
</script>
