<template>
    <slot :hasChanges="hasChanges" :cancel="cancel"></slot>
</template>

<script setup>
import i18n from '~/plugins/i18n.js'
import { ref, watch, inject } from 'vue'
import { onBeforeRouteLeave } from 'vue-router'

const swal = inject('$swal')
const model = defineModel()
const cache = ref(model.value)
const hasChanges = ref(false)

const init = () => {
    hasChanges.value = false
    cache.value = JSON.parse(JSON.stringify(model.value))
}

const cancel = () => {
    hasChanges.value = false
    model.value = JSON.parse(JSON.stringify(cache.value))
}

defineExpose({ init })

watch(() => model.value, (newValue, oldValue) => {
    const oldKeys = Object.keys(oldValue || {}).length
    const newKeys = Object.keys(newValue || {}).length

    // structure changed, reinitialize
    if (newKeys > oldKeys) return init()

    hasChanges.value = JSON.stringify(model.value) !== JSON.stringify(cache.value)
}, { deep: true })

onBeforeRouteLeave(async (to) => {
    // always allow logging out
    if (to.name === 'login') return

    if (hasChanges.value) {
        return await swal({
            showCancelButton: true,
            cancelButtonText: i18n.global.t('main.discard_changes'),
            confirmButtonText: i18n.global.t('main.cancel'),
            title: i18n.global.t('main.unsaved_changes'),
        }).then(result => result.dismiss === 'cancel')
    }
})
</script>