<template>
    <div>
        <Errors :errors="errors" class="mb-4" />
        
        <input class="chrome-autofill-email" type="email" autocomplete="username" />

        <div v-if="currentPw" class="form-group" :class="{ 'has-error': v$.password.$error }">
            <input type="password" class="form-input" v-model="password" :placeholder="$t('auth.current_password')" />
        </div>

        <PasswordInput v-model="new_password" :placeholder="$t('auth.new_password')"></PasswordInput>

        <div class="form-group">
            <SaveButton @click="updatePassword()" class="mr-2"></SaveButton>
            <button v-if="cancelBtn" class="btn" @click="$emit('close')">{{ $t('main.cancel') }}</button>
        </div>
    </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import PasswordInput from '~/composition/PasswordInput.vue'

export default {
    props: { cancelBtn: Boolean, currentPw: Boolean },

    components: { PasswordInput },

    setup: () => ({ v$: useVuelidate() }),

    data() {
        return {
            errors: null,

            password: '',
            new_password: '',
        }
    },

    validations() {
        if (this.currentPw) return { password: { required } }
    },

    methods: {
        async updatePassword() {
            if (!await this.v$.$validate()) return

            this.errors = null

            try {
                await this.$axios.post('user', { password: this.password, new_password: this.new_password })

                this.$emit('close')
                window.toast(this.$root.$t('i.update', { i: this.$root.$t('users.password') }))

            } catch ({ response }) {
                this.errors = response
            }
        },
    },
}
</script>
