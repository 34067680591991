<template>
    <div>
        <Modal v-if="edit" :title="edit.name" @close="edit = false">
            <form @submit.prevent="updateField(edit)">
                <FileFieldsModel :field="edit" v-model="newValue" autoFocus />
            </form>

            <template #footer>
                <button @click="updateField(edit)" class="btn btn-primary mr-2">{{ $t('main.save') }}</button>
                <button @click="deleteField(edit)" class="btn btn-error">{{ $t('main.delete') }}</button>
            </template>
        </Modal>

        <div class="field-wrapper">
            <div v-for="field in filteredFields.slice(0, limit)" :key="field.id">
                <div class="field c-hand" @click="editField(field, $event)">
                    <div class="field-color" :style="{ backgroundColor: field.color }"></div>
                    <div class="text-bold">{{ field.name }}</div>
                    <div class="field-value">
                        <template v-if="field.type === 'checkbox'">
                            <i class="far fw" :class="field.value == '1' ? 'fa-check-square' : 'fa-square'"></i>
                        </template>
                        <span v-else>{{ field.value }}</span>
                    </div>
                </div>
            </div>
        </div>

        <a href="#" v-if="showAllFields" @click.prevent="toggleLimit()">
            <span class="text-tiny">{{ $t('files.filter_less') }}</span>
        </a>
        <a href="#" v-else-if="filteredFields.length > limit" @click.prevent="toggleLimit()">
            <span class="text-tiny">{{ $t('files.filter_all') }}</span>
        </a>
    </div>
</template>

<script>
import FileFieldsModel from '~/components/files/FileFieldsModel.vue'

const DEFAULT_FIELDS_LIMIT = 25
const DEFAULT_FIELDS_LIMIT_ON_FILES_INDEX = 5

export default {
    components: { FileFieldsModel },

    props: {
        fields: Array,
        indexPage: Boolean,
        hideSnapFields: Boolean,
    },

    data: () => ({
        edit: false,
        newValue: null,

        showAllFields: false,
        limit: DEFAULT_FIELDS_LIMIT,
    }),

    created() {
        if (this.indexPage) {
            this.limit = DEFAULT_FIELDS_LIMIT_ON_FILES_INDEX
        }
    },

    computed: {
        filteredFields() {
            if (!this.fields) return []

            if (this.indexPage) {
                // hide duplicate name + value combinations
                return this.fields.filter((field, index, self) =>
                    index === self.findIndex(f => f.name + f.value === field.name + field.value)
                )
            }

            if (this.hideSnapFields) {
                // hide fields that have a snap_id
                return this.fields.filter(field => field.snap_id === null)
            }

            return this.fields
        },
    },

    methods: {
        toggleLimit() {
            if (this.showAllFields) {
                this.limit = this.indexPage ? DEFAULT_FIELDS_LIMIT_ON_FILES_INDEX : DEFAULT_FIELDS_LIMIT
            } else {
                this.limit = 999
            }

            this.showAllFields = !this.showAllFields
        },

        editField(field, event) {
            const canEdit =
                this.$store.getters.can('Create files') && field.tenant_id === this.$store.state.user.tenant_id

            if (!canEdit) return

            // if checkbox inline toggle 
            if (event.target.tagName === 'I') {
                this.newValue = field.value = field.value == '1' ? '0' : '1'
                this.updateField(field)
            } else {
                this.newValue = field.value
                this.edit = field
            }
        },

        updateField(field) {
            this.edit = false
            field.value = this.newValue

            this.$axios.patch(`files_fields/${field.id}`, field).then(() => {
                window.toast(this.$root.$t('i.update', { i: field.name }))
            })
        },

        deleteField(field) {
            this.$swal({
                showCancelButton: true,
                title: this.$t('main.are_you_sure'),
                text: this.$t('main.you_wont_be_able_to_revert_this'),
                confirmButtonText: this.$t('main.yes_delete_it'),
                cancelButtonText: this.$t('main.cancel'),
            }).then(result => {
                if (result.value) {
                    this.edit = false
                    this.fields.splice(this.fields.indexOf(field), 1)

                    this.$axios.delete(`files_fields/${field.id}`).then(() => {
                        window.toast(this.$root.$t('i.delete', { i: field.name }))
                    })
                }
            })
        },
    },
}
</script>
