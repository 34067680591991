import i18n from '~/plugins/i18n.js'

export const duplicateWorkflow = async (workflow, axios, swal) => {
    const { value } = await swal({
        showCancelButton: true,
        confirmButtonColor: '#0092c8',
        title: i18n.global.t('workflows.duplicate_this_workflow'),
        confirmButtonText: i18n.global.t('main.confirm'),
        cancelButtonText: i18n.global.t('main.cancel'),
    })

    if (!value) return await new Promise(() => { })

    const { data } = await axios.get(`workflows/${workflow.id}`)

    Object.assign(workflow, data)

    const duplicated = JSON.parse(JSON.stringify(workflow))

    duplicated.name = duplicated.name + ' (Copy)'

    delete duplicated.id
    duplicated.steps.forEach(step => delete step.id)

    return await axios.post('workflows', duplicated)
}

export const deleteWorkflow = async (workflow, axios, swal) => {
    const { value } = await swal({
        showCancelButton: true,
        title: i18n.global.t('main.are_you_sure'),
        text: i18n.global.t('main.you_wont_be_able_to_revert_this'),
        confirmButtonText: i18n.global.t('main.yes_delete_it'),
        cancelButtonText: i18n.global.t('main.cancel'),
    })

    if (!value) return await new Promise(() => { })

    return await axios.delete(`workflows/${workflow.id}`)
}
