<template>
    <WatchForUnsavedChanges ref="watch" v-model="permissions" v-slot="{ hasChanges, cancel }">
        <ContextBar :title="$t('main.permissions')"></ContextBar>
        <Errors :errors="errors" />

        <div v-if="permissions">
            <table class="table">
                <tbody>
                    <tr v-for="(_, permission) in permissions">
                        <td>
                            <div class="flex-space">
                                <div>{{ $t(`permissions.${permission}`) }}</div>
                                <select v-model="permissions[permission]" class="form-select mw-250">
                                    <option :value="{ SuperAdmin: false, Manager: false, User: false }">
                                        {{ $t('permissions.role_none') }}
                                    </option>
                                    <option :value="{ SuperAdmin: true, Manager: false, User: false }">
                                        {{ $t('permissions.role_super_admin') }}
                                    </option>
                                    <option :value="{ SuperAdmin: true, Manager: true, User: false }">
                                        {{ $t('permissions.role_super_admin_manager') }}
                                    </option>
                                    <option :value="{ SuperAdmin: true, Manager: true, User: true }">
                                        {{ $t('permissions.role_super_admin_manager_user') }}
                                    </option>
                                </select>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>

            <div class="context-bar">
                <SaveButton @click="save()" :disabled="!hasChanges" />
                <button class="btn" @click="cancel" :disabled="!hasChanges">{{ $t('main.cancel') }}</button>
            </div>
        </div>
        <Spinner v-else></Spinner>
    </WatchForUnsavedChanges>
</template>

<script>
export default {
    data: () => ({ errors: null, permissions: null }),

    async mounted() {
        const { data: permissions } = await this.$axios.get('permissions')

        this.permissions = permissions
    },

    methods: {
        async save() {
            try {
                await this.$axios.post('permissions', this.permissions)

                window.toast(this.$root.$t('i.update', { i: this.$root.$t('main.permissions') }))
                this.$store.dispatch('fetchUser')
                this.$refs.watch?.init()

            } catch ({ response }) {
                this.errors = response
            }
        },
    },
}
</script>
