<script setup>
import { useAttrs, defineAsyncComponent } from 'vue'

const attrs = useAttrs()
const model = defineModel()

const DatePicker = defineAsyncComponent(() => import('./DatePicker.vue'))
</script>

<template>
    <Suspense>
        <DatePicker v-model="model" v-bind="attrs" />
        <template #fallback>
            <input class="form-input" style="width: 300px" />
        </template>
    </Suspense>
</template>