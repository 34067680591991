<template>
    <form @submit.prevent="submit()">
        <div class="form-group">
            <h2>{{ $t('auth.forgot_password') }}</h2>
        </div>

        <div class="form-group">
            <router-link :to="{ name: 'login' }">
                <i class="fas fa-arrow-left"></i>
                <span class="ml-2">{{ $t('main.back') }}</span>
            </router-link>
        </div>

        <Errors :errors="errors" />

        <div class="form-group" :class="{ 'has-error': v$.email.$error }">
            <label class="form-label" for="email">{{ $t('users.email') }}</label>
            <input v-model="email" id="email" class="form-input" />

            <div v-if="v$.email.$error" class="form-input-hint">{{ $t('auth.email_address_must_be_valid') }}</div>
        </div>

        <div class="form-group">
            <button type="submit" class="btn btn-primary" :class="{ loading }" :disabled="done">
                <span>{{ $t('auth.send_password_reset_link') }}</span>
            </button>
        </div>
    </form>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'

export default {
    setup: () => ({ v$: useVuelidate() }),

    data: () => ({
        email: '',

        done: false,
        errors: null,
        loading: false,
    }),

    validations() {
        return {
            email: { required, email },
        }
    },

    methods: {
        async submit() {
            if (!await this.v$.$validate()) return

            this.errors = null
            this.loading = true

            this.$axios
                .post('auth/password/email', { email: this.email })
                .then(() => {
                    this.done = true
                    this.loading = false
                    window.toast(this.$root.$t('auth.password_reset_link_sent'))
                })
                .catch(({ response }) => {
                    this.loading = false
                    this.errors = response
                })
        },
    },
}
</script>
