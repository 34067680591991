<template>
    <form @submit.prevent="submit()">
        <div class="form-group">
            <h2>{{ $t('auth.get_started_today') }}</h2>
        </div>

        <div class="form-group">
            <router-link :to="{ name: 'login' }">
                {{ $t('auth.if_you_are_already_registered_click_here_to_login') }}
            </router-link>
        </div>

        <div class="form-group">
            <Errors :errors="errors" />
        </div>

        <div class="form-group" :class="{ 'has-error': v$.name.$error }">
            <label class="form-label" for="name">{{ $t('users.name') }}</label>
            <input v-model="name" id="name" class="form-input" />
        </div>

        <div class="form-group" :class="{ 'has-error': v$.company.$error }">
            <label class="form-label" for="company">{{ $t('auth.company') }}</label>
            <input v-model="company" id="company" class="form-input" />
        </div>

        <div class="form-group" :class="{ 'has-error': v$.email.$error }">
            <label class="form-label" for="email">{{ $t('users.email') }}</label>
            <input v-model="email" id="email" type="email" class="form-input" autocomplete="email" />

            <div v-if="!v$.email.email" class="form-input-hint">{{ $t('auth.email_address_must_be_valid') }}</div>
        </div>

        <PasswordInput v-model="password" label></PasswordInput>

        <div class="form-group flex-start">
            <label class="form-checkbox">
                <input id="tos" type="checkbox" v-model="TOSAccepted" />
                <i class="form-icon"></i>
            </label>
            <label for="tos" v-html="tos"></label>
        </div>

        <div class="form-group">
            <button type="submit" class="btn btn-primary" :disabled="!TOSAccepted || !captchaReady"
                :class="{ loading }">
                <span>{{ $t('auth.create_account') }}</span>
            </button>
        </div>

        <div class="text-small">
            This site is protected by reCAPTCHA and the Google
            <a href="https://policies.google.com/privacy">Privacy Policy</a>
            and
            <a href="https://policies.google.com/terms">Terms of Service</a>
            apply.
        </div>
    </form>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
import PasswordInput from '~/composition/PasswordInput.vue'

export default {
    setup: () => ({ v$: useVuelidate() }),

    components: { PasswordInput },

    data() {
        return {
            errors: null,
            loading: false,

            tos: null,
            TOSAccepted: false,
            captchaReady: !!window.grecaptcha,

            type: this.$route.query.type,
            name: this.$route.query.name,
            email: this.$route.query.email,
            company: this.$route.query.company,
            password: '',
        }
    },

    validations() {
        return {
            name: { required },
            company: { required },
            email: { required, email },
        }
    },

    async mounted() {
        if (this.email) {
            this.company = (this.email.split('@')[1] || '').split('.')[0] || ''
        }

        const terms = this.$t('auth.terms')
        const privacy = this.$t('auth.privacy')
        const cookie_use = this.$t('auth.cookie_use')

        this.tos = this.$t('auth.tos', {
            terms: `<a href='https://www.cargosnap.com/terms-of-use' target='_blank'>${terms}</a>`,
            privacy: `<a href='https://www.cargosnap.com/privacy-terms' target='_blank'>${privacy}</a>`,
            cookie_use: `<a href='https://www.cargosnap.com/cookie-use-terms' target='_blank'>${cookie_use}</a>`,
        })

        if (this.captchaReady) return

        const script = document.createElement('script')
        script.setAttribute('src', `https://www.google.com/recaptcha/api.js?render=${window.config.siteKey}`)

        script.async = true
        script.onload = () => {
            window.grecaptcha.ready(() => {
                this.captchaReady = true
            })
        }

        document.head.appendChild(script)
    },

    methods: {
        async submit() {
            if (!await this.v$.$validate()) return

            this.errors = null
            this.loading = true

            const token = await window.grecaptcha.execute(window.config.siteKey, { action: 'register' })

            try {
                await this.$axios.post('auth/register', {
                    name: this.name,
                    email: this.email,
                    password: this.password,
                    company: this.company,
                    domain: this.email.replace(/.*@/, ''),
                    location: `${this.company}'s Team`,
                    language: this.$store.state.language,
                    type: this.type || 'trial',
                    'g-recaptcha-response': token,
                })

                this.$gtag.event('onboarding_button_register_submit')

                const { data } = await this.$axios.post('auth/login', { email: this.email, password: this.password })

                this.$store.dispatch('startSession', data)
                this.$router.replace({ name: 'dashboard' })

            } catch ({ response }) {
                this.errors = response
            }

            this.loading = false
        },
    },
}
</script>
