<template>
    <table class="table">
        <tbody>
            <tr>
                <td class="text-gray">{{ $t('devices.attr_app_version') }}</td>
                <td>
                    <DeviceVersionStatus :version="version" :platform="attributes?.platform"
                        :sideload="attributes?.sideload"></DeviceVersionStatus>
                </td>
            </tr>
            <template v-if="attributes">
                <tr v-if="attributes.platform">
                    <td class="text-gray">{{ $t('devices.attr_platform') }}</td>
                    <td>
                        <span>{{ attributes.platform }}</span>
                        <i class="fab mx-1" :class="[attributes.platform === 'ios' ? 'fa-apple' : 'fa-android']"></i>
                        <span v-if="attributes.platform_version">({{ attributes.platform_version }})</span>
                    </td>
                </tr>
                <tr v-if="attributes.model">
                    <td class="text-gray">{{ $t('devices.attr_model') }}</td>
                    <td>{{ attributes.model }}</td>
                </tr>
                <tr v-if="attributes.manufacturer">
                    <td class="text-gray">{{ $t('devices.attr_manufacturer') }}</td>
                    <td>{{ attributes.manufacturer }}</td>
                </tr>
                <tr v-if="attributes.locale">
                    <td class="text-gray">{{ $t('devices.attr_locale') }}</td>
                    <td>{{ attributes.locale }}</td>
                </tr>
            </template>
        </tbody>
    </table>
</template>

<script>
import DeviceVersionStatus from '~/components/DeviceVersionStatus.vue'

export default {
    components: { DeviceVersionStatus },

    props: { version: String, attributes: Object }
}
</script>