<template>
    <button class="btn btn-primary" :class="{ loading }" @click="click">
        <slot>{{ $t('main.save') }}</slot>
    </button>
</template>

<script>
export default {
    props: { onClick: Function },

    data: () => ({ loading: false }),

    methods: {
        async click() {
            if (this.loading) return

            this.loading = true

            await this.onClick()

            this.loading = false
        }
    }
}
</script>