import i18n from '~/plugins/i18n.js'

export const deleteForm = async (form, axios, swal) => {
    const { value } = await swal({
        showCancelButton: true,
        title: i18n.global.t('main.are_you_sure'),
        text: i18n.global.t('main.you_wont_be_able_to_revert_this'),
        confirmButtonText: i18n.global.t('main.yes_delete_it'),
        cancelButtonText: i18n.global.t('main.cancel'),
    })

    if (!value) return await new Promise(() => { })

    await axios.delete(`forms/${form.id}`)
}

export const duplicateForm = async (form, axios, swal) => {
    const { value } = await swal({
        showCancelButton: true,
        confirmButtonColor: '#0092c8',
        title: i18n.global.t('forms.duplicate_this_form'),
        confirmButtonText: i18n.global.t('main.confirm'),
        cancelButtonText: i18n.global.t('main.cancel'),
    })

    if (!value) return await new Promise(() => { })

    const { data } = await axios.get(`forms/${form.id}`)

    Object.assign(form, data)

    const duplicated = JSON.parse(JSON.stringify(form))

    duplicated.title = duplicated.title + ' (Copy)'

    delete duplicated.id
    duplicated.fields.forEach(field => delete field.id)

    return await axios.post('forms', duplicated)
}