export default {
    siteKey: import.meta.env.VITE_GOOGLE_RECAPTCHA_KEY,

    locales: {
        da: 'Dansk',
        de: 'Deutsch',
        en: 'English',
        es: 'Español',
        fr: 'Français',
        it: 'Italiano',
        lv: 'Latviski',
        nl: 'Nederlands',
        pl: 'Polska',
        br: 'Português (BR)',
        pt: 'Português (PT)',
        ru: 'Русский',
        ro: 'Română',
        se: 'Svenska',
        tr: 'Türkçe',
        zh: '普通话',
    },

    currencies: {
        EUR: { id: 0, name: 'Euro', value: 'EUR', symbol: '€' },
        USD: { id: 1, name: 'US Dollar', value: 'USD', symbol: '$' },
        BRL: { id: 2, name: 'Brazilian Real', value: 'BRL', symbol: 'R$' },
    },

    openStreetMap: {
        skin: 'https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png',
        copyright:
            '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> &copy; <a href="https://carto.com/attributions">CARTO</a>',
    },

    snapDeletedReasons: {
        1: 'Quality; too dark',
        2: 'Quality; not sharp',
        3: 'Quality; bad angle',
        4: 'Snap does not belong to File',
        5: 'Inappropriate to show',
    },
}
