<template>
    <div :id="`upload-${item.id}`" class="timeline-item"
        :class="{ 'has-damage': item.has_damage, manual: !item.device_id }">
        <div class="timeline-left">
            <a :href="`#upload-${item.id}`" class="timeline-icon">
                <i class="fas fa-sm"></i>
            </a>
        </div>
        <div class="timeline-content">
            <div class="timeline-title">
                <span>{{ $t(`timeline.${item.upload_type}`) }}</span>
                <span class="text-small">
                    <i class="far fa-clock mr-1"></i>
                    <DateTime :date="item.created_at" ago></DateTime>
                </span>
            </div>

            <div class="box">
                <div class="columns">
                    <div class="column col-3 col-xl-5 col-md-12">
                        <img v-if="preLoad" :src="item.image_thumb_sm" class="img-responsive" />
                        <video v-else-if="item.upload_type === 'video'" width="300" height="300" preload="metadata"
                            controls>
                            <source :src="item.image_url" type="video/mp4" />
                        </video>
                        <img v-else width="300" v-lazy="item.image_thumb_sm" :data-src="item.image_thumb_sm"
                            :data-source="item.image_url" class="img-responsive c-hand" alt />
                    </div>
                    <div class="column">
                        <table class="table">
                            <tbody>
                                <tr v-if="showReference">
                                    <td>{{ $t('files.reference') }}</td>
                                    <td>
                                        <router-link :to="{ name: 'files.show', params: { id: item.file_id } }">
                                            {{ item.scan_code }}
                                        </router-link>
                                    </td>
                                </tr>
                                <tr class="date-time">
                                    <td>{{ $t('timeline.snap_date_time') }}</td>
                                    <td>
                                        <DateTime :date="item.scan_date_time"></DateTime>
                                    </td>
                                </tr>
                                <tr class="place" v-if="item.latitude">
                                    <td>{{ $t('timeline.place') }}</td>
                                    <td>
                                        <a :href="`#map-${item.id}`">
                                            <span v-if="freeFormAddress">{{ freeFormAddress }}</span>
                                            <span v-else>{{ item.latitude }} / {{ item.longitude }}</span>
                                        </a>

                                        <div class="weather flex-end text-clip" v-if="item.properties?.weather">
                                            <div :class="`w-${item.properties.weather.weather_code}`"></div>
                                            <div>{{ item.properties.weather.temperature }}&deg;</div>
                                            <div>{{ $t(`weather.${item.properties.weather.weather_code}`) }}</div>
                                        </div>
                                    </td>
                                </tr>
                                <tr class="uploaded-by" v-if="!hideUploader">
                                    <td>{{ $t('timeline.uploaded_by') }}</td>
                                    <td>
                                        <router-link v-if="item.device_id"
                                            :to="{ name: 'settings.devices.show', params: { id: item.device_id } }">
                                            {{ item.device_nick }}
                                        </router-link>
                                        <div v-else>{{ item.device_nick }}</div>
                                    </td>
                                </tr>
                                <tr class="damage-type" v-if="item.has_damage">
                                    <td>{{ $t('timeline.damage_type') }}</td>
                                    <td>{{ item.damage_type_desc }}</td>
                                </tr>
                                <tr class="document-type" v-if="item.document_type_id">
                                    <td>{{ $t('timeline.document_type') }}</td>
                                    <td>{{ item.document_type_desc }}</td>
                                </tr>
                                <tr class="workflow-item" v-if="item.workflow_step_id">
                                    <td>{{ item.workflow_description }}</td>
                                    <td>{{ item.workflow_step_description }}</td>
                                </tr>
                                <tr class="comment" v-if="item.comment">
                                    <td>{{ $t('timeline.comment') }}</td>
                                    <td>{{ item.comment }}</td>
                                </tr>
                            </tbody>
                        </table>

                        <Fields :fields="item.fields" class="my-2"></Fields>

                        <div class="text-right">
                            <button @click="$emit('delete', item)" class="delete-snap btn mr-2">
                                <i class="fas fa-trash"></i>
                            </button>

                            <button @click="$emit('edit', item)" class="edit-snap btn mr-2">
                                <i class="fas fa-pencil"></i>
                            </button>

                            <a :href="item.image_url" target="_blank" class="btn mr-2">
                                <i class="far fa-circle-down"></i>
                            </a>

                            <button @click="$emit('share', item)" class="edit-snap btn"
                                :class="{ 'text-gray': !item.include_in_share }">
                                <i class="fas fa-share-alt"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Fields from '~/components/files/FileFields.vue'

export default {
    props: {
        item: Object,
        preLoad: Boolean,
        showReference: Boolean,
        hideUploader: Boolean,
    },

    components: { Fields },

    computed: {
        freeFormAddress() {
            return this.item.geocoding?.addresses[0]?.address.freeformAddress
        }
    },
}
</script>
