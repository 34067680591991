<template>
    <WatchForUnsavedChanges ref="watch" v-model="tenant" v-slot="{ hasChanges, cancel }">
        <ContextBar :title="$t('main.general')"></ContextBar>

        <Errors :errors="errors" />

        <table class="table">
            <tbody>
                <tr>
                    <td :class="{ 'text-gray': tenant.close_file_after_days === '' }">
                        <label for="close_file_after_days" class="form-label">
                            {{ $t('settings.auto_close_after_days_of_inactivity', { days: tenant.close_file_after_days
                            }) }}
                        </label>
                    </td>
                    <td>
                        <input id="close_file_after_days" type="number" v-model.number="tenant.close_file_after_days"
                            min="0" class="form-input" placeholder="∞" />
                    </td>
                </tr>
                <tr v-if="!$store.state.user.properties.use_report_in_browser">
                    <td :class="{ 'text-gray': tenant.auto_delete_reports_after_days === '' }">
                        <label for="auto_delete_reports_after_days" class="form-label">
                            {{ $t('settings.auto_delete_reports_after_days', { days:
                            tenant.auto_delete_reports_after_days
                            })}}
                        </label>
                    </td>
                    <td>
                        <input id="auto_delete_reports_after_days" type="number"
                            v-model.number="tenant.auto_delete_reports_after_days" min="0" class="form-input"
                            placeholder="∞" />
                    </td>
                </tr>
                <tr>
                    <td>
                        <label for="inbox_rule" class="form-label">{{ $t('inbox_rules.share_with') }}</label>
                    </td>
                    <td>
                        <select v-model="tenant.inbox_rule" class="form-select" id="inbox_rule">
                            <option v-for="rule in inbox_rules" :value="rule">{{ $t(`inbox_rules.${rule}`) }}</option>
                        </select>
                    </td>
                </tr>
                <tr>
                    <td>
                        <label for="logo" class="form-label">{{ $t('share.logo') }}</label>
                    </td>
                    <td>
                        <div v-if="logo_url" class="flex-space">
                            <img width="100" :src="logo_url" />
                            <button class="btn ml-2" @click="deleteLogo()">{{ $t('share.logo_delete') }}</button>
                        </div>

                        <div v-else class="input-group">
                            <input type="file" ref="logo" id="logo" class="form-input" accept="image/*" />
                            <SaveButton @click="uploadLogo()" class="input-group-btn">
                                {{ $t('files.upload') }}</SaveButton>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <label class="form-label">
                            {{ $t('settings.uploads_of_type_automatically_available_in_share')}}
                        </label>
                    </td>
                    <td class="text-right">
                        <label class="form-checkbox d-inline-block mr-2">
                            <input type="checkbox" v-model="tenant.new_snaps_included_in_share" />
                            <i class="form-icon"></i>
                            <span>{{ $t('files.filter_snap') }}</span>
                        </label>
                        <label class="form-checkbox d-inline-block mr-2">
                            <input type="checkbox" v-model="tenant.new_snaps_with_damage_included_in_share" />
                            <i class="form-icon"></i>
                            <span>{{ $t('files.filter_snap_with_damage') }}</span>
                        </label>
                        <label class="form-checkbox d-inline-block">
                            <input type="checkbox" v-model="tenant.new_documents_included_in_share" />
                            <i class="form-icon"></i>
                            <span>{{ $t('files.filter_document') }}</span>
                        </label>
                    </td>
                </tr>
                <tr v-if="$store.state.jwt.microsoft">
                    <td>
                        <label for="login_with_sso" class="form-label">{{ $t('settings.login_with_sso') }}</label>
                    </td>
                    <td>
                        <label class="form-checkbox">
                            <input type="checkbox" id="login_with_sso" v-model="tenant.login_enforce_sso"
                                :true-value="1" :false-value="0" @change="confirmEnforceLoginSSO()" />
                            <i class="form-icon"></i>
                            <span></span>
                        </label>
                    </td>
                </tr>
            </tbody>
        </table>

        <div class="context-bar">
            <SaveButton @click="save()" :disabled="!hasChanges" />
            <button class="btn" @click="cancel" :disabled="!hasChanges">{{ $t('main.cancel') }}</button>
        </div>
    </WatchForUnsavedChanges>
</template>
<script>
export default {
    data() {
        return {
            tenant: {},
            logo_url: null,
            errors: null,
            inbox_rules: ['USER', 'LOCATION', 'COMPANY'],
        }
    },

    async mounted() {
        const { data: tenant } = await this.$axios.get('tenant')

        this.tenant = tenant
        this.logo_url = tenant.logo_url
    },

    methods: {
        async save() {
            try {
                await this.$axios.post('tenant', this.tenant)
                window.toast(this.$root.$t('i.update', { i: this.$root.$t('main.settings') }))
                this.$store.dispatch('fetchUser')
                this.$refs.watch?.init()
            } catch ({ response }) {
                this.errors = response
            }
        },

        async uploadLogo() {
            const files = this.$refs.logo.files

            if (!files.length) return

            const formData = new FormData()
            formData.append('uploadfile', files[0])

            try {
                const { data } = await this.$axios.post('tenant/logo', formData, { headers: { 'Content-Type': 'multipart/form-data' } })

                this.logo_url = data.logo_url
            } catch ({ response }) {
                this.errors = response
            }
        },

        deleteLogo() {
            this.$swal({
                showCancelButton: true,
                title: this.$t('main.are_you_sure'),
                text: this.$t('main.you_wont_be_able_to_revert_this'),
                confirmButtonText: this.$t('main.yes_delete_it'),
                cancelButtonText: this.$t('main.cancel'),
            }).then(result => {
                if (!result.value) return

                this.logo_url = null
                this.$axios.delete('tenant/logo')
            })
        },

        confirmEnforceLoginSSO() {
            if (!this.tenant.login_enforce_sso) return

            this.$swal({
                confirmButtonColor: '#0092c8',
                title: this.$t('main.are_you_sure'),
                text: this.$t('settings.login_with_sso_warning'),
            })
        },
    },
}
</script>
