<template>
    <Spinner v-if="!submit.id"></Spinner>
    <Modal v-else :title="$t('kiosk.editandaccept')" @close="$router.push({ name: 'kiosk_submits' })">
        <div class="form-group">
            <Errors :errors="errors" />
        </div>

        <div class="form-group" v-for="answer in submit.answers" :key="answer.id">
            <label class="form-label" :class="{ 'text-bold': answer.step.model === 'reference' }">
                {{ answer.step.question }}
            </label>
            <input v-model="answer.answer" class="form-input" />
        </div>

        <template #footer>
            <SaveButton @click="acceptSubmit()">{{ $t('main.create') }}</SaveButton>
        </template>
    </Modal>
</template>

<script>
export default {
    data() {
        return {
            errors: null,
            id: this.$route.params.id,
            submit: {},
        }
    },

    async mounted() {
        const { data: submit } = await this.$axios.get(`kiosk_submits/${this.id}`)

        this.submit = submit
    },

    methods: {
        async acceptSubmit() {
            try {
                await this.$axios.post(`kiosk_submits/${this.submit.id}/accept`, this.submit)

                this.$emit('refresh')
                this.$router.push({ name: 'kiosk_submits' })
                window.toast(this.$root.$t('webhooks.NEW_FILE_CREATED'))

            } catch ({ response }) {
                this.errors = response
            }
        },
    },
}
</script>
