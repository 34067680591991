<template>
    <Modal v-if="show" :title="$t('regex.reference_warning')" @close="show = false">
        <ul class="tab mb-4">
            <li class="tab-item" :class="{ active: build.match_all }">
                <a href="#" @click.prevent="build.match_all = true">{{ $t('regex.match_all') }}</a>
            </li>
            <li class="tab-item" :class="{ active: !build.match_all }">
                <a href="#" @click.prevent="build.match_all = false">{{ $t('regex.configured') }}</a>
            </li>
        </ul>

        <div v-if="build.match_all">{{ $t('devices.all_scancodes_accepted') }}</div>
        <div v-else>
            <div class="flex-start mb-2">
                <label class="form-checkbox d-inline mr-1">
                    <input v-model="build.between_digits" type="checkbox" />
                    <i class="form-icon"></i>
                    <span>{{ $t('regex.between_number_of_digits') }}</span>
                </label>

                <div v-if="build.between_digits" class="flex-start">
                    <input v-model="build.between_digits_min" type="number" class="form-input" style="flex:1" min="3"
                        max="30" />
                    <span class="mx-2">&</span>
                    <input v-model="build.between_digits_max" type="number" class="form-input" style="flex:1" min="3"
                        max="30" />
                    <label class="form-checkbox d-inline ml-2">
                        <input v-model="build.between_digits_leading_zeros" type="checkbox" />
                        <i class="form-icon"></i>
                        <span>{{ $t('regex.leading_zeros') }}</span>
                    </label>
                </div>
            </div>
            <div class="flex-start mb-4">
                <label class="form-checkbox mr-1">
                    <input v-model="build.container_code" type="checkbox" />
                    <i class="form-icon"></i>
                    <span>{{ $t('regex.use_container_code') }}</span>
                </label>

                <div v-if="build.container_code" class="flex-start">
                    <label class="form-checkbox">
                        <input v-model="build.container_code_strict" type="checkbox" />
                        <i class="form-icon"></i>
                        <span>{{ $t('regex.strict_iso_6346_format') }}</span>
                    </label>
                </div>
            </div>

            <div class="form-group" :class="{ 'has-error': !newCustomRegexValid }">
                <input class="form-input w-250" v-model="newCustomRegex"
                    :placeholder="$t('regex.regular_expression')" />
            </div>
        </div>

        <template #footer>
            <div class="flex-space">
                <div class="input-group">
                    <input class="form-input" v-model="scan_code"
                        :placeholder="`${$t('regex.test_code')} (${$t('files.reference')})`" />
                    <button type="button" @click="testScanCode()" class="btn input-group-btn">
                        {{ $t('regex.test_code') }}
                    </button>
                </div>
                <button @click="save()" class="btn btn-primary">{{ $t('main.save') }}</button>
            </div>
        </template>
    </Modal>
</template>

<script>
export default {
    props: ['regex', 'custom'],

    data() {
        return {
            show: false,

            scan_code: '',

            newRegex: this.regex,
            newCustomRegex: this.custom,
            newCustomRegexValid: true,

            build: {
                match_all: false,

                between_digits: false,
                between_digits_min: 3,
                between_digits_max: 3,
                between_digits_leading_zeros: false,

                container_code: false,
                container_code_strict: false,
            },
        }
    },

    watch: {
        build: {
            deep: true,
            handler: 'rebuildRegex',
        },

        newCustomRegex(regex) {
            try {
                new RegExp(regex)
                this.newCustomRegexValid = true
            } catch {
                this.newCustomRegexValid = false
            }
        },
    },

    methods: {
        showModal() {
            this.show = true
            this.initialize()
        },

        initialize() {
            const splits = this.regex.split('|')
            const find = splits.find(s => s === '.*')

            if (find) {
                this.build.match_all = true
            } else {
                if (splits[1] && splits[1].charAt(0) == '^') {
                    this.build.between_digits = true

                    let range = splits[1].split('{').pop().split('}').shift().split(',')

                    if (splits[1].charAt(2) === '1') {
                        range[0]++
                        range[1]++
                    } else {
                        this.build.between_digits_leading_zeros = true
                    }

                    this.build.between_digits_min = range[0]
                    this.build.between_digits_max = range[1]
                }

                if (splits[2] && splits[2].charAt(0) == '^') {
                    this.build.container_code = true

                    if (splits[2].charAt(2) == 'A') {
                        this.build.container_code_strict = true
                    }
                }
            }
        },

        testScanCode() {
            let string = this.newRegex

            if (this.newCustomRegex && this.newCustomRegexValid) {
                string += '|' + this.newCustomRegex
            }

            const matches = new RegExp(string).test(this.scan_code)

            if (matches) {
                window.toast(this.$root.$t('regex.match'))
            } else {
                window.toast(this.$root.$t('regex.no_match'), 'error')
            }
        },

        save() {
            // fallback to catch-all if matches nothing
            const fallback = this.newRegex + this.newCustomRegex === '•|•|•'
            const newReg = fallback ? '.*' : this.newRegex

            const newCustReg = this.newCustomRegexValid ? this.newCustomRegex : ''

            this.$emit('save', newReg, newCustReg)
            this.show = false
        },

        rebuildRegex(build) {
            let splits = []

            if (build.match_all) {
                splits.push('.*')
            } else {
                splits.push('•')

                if (build.between_digits) {
                    if (build.between_digits_leading_zeros) {
                        splits.push(`^[0-9]{${build.between_digits_min},${build.between_digits_max}}$`)
                    } else {
                        splits.push(`^[1-9][0-9]{${build.between_digits_min - 1},${build.between_digits_max - 1}}$`)
                    }
                } else {
                    splits.push('•')
                }

                if (build.container_code) {
                    if (build.container_code_strict) {
                        splits.push('^[A-Z]{3}[U|J|Z][0-9]{7}$')
                    } else {
                        splits.push('^[a-zA-Z]{4}[0-9]{7}$')
                    }
                } else {
                    splits.push('•')
                }
            }

            this.newRegex = splits.join('|')
        },
    },
}
</script>
