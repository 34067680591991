<template>
    <Spinner v-if="!forms.length"></Spinner>
    <Modal v-else :title="$t('files.select_a_form_to_submit')" @close="$router.push({ name: 'files.show' })">
        <div class="form-group">
            <MultiSelect label="title" :options="forms" :placeholder="$t('workflows.choose_form')"
                @option:selected="onSelected"></MultiSelect>
        </div>
    </Modal>
</template>

<script>
export default {
    data: () => ({ forms: [] }),

    async mounted() {
        const { data: paginator } = await this.$axios.get('forms', {
            params: { limit: 200, sort: 'title', by: 'asc' },
        })

        const forms = paginator.data

        if (!forms.length) {
            window.toast(this.$root.$t('forms.no_forms_found'), 'error')

            return this.$router.push({ name: 'files.show' })
        }

        if (forms.length === 1) {
            return this.onSelected(forms[0])
        }

        this.forms = forms
    },

    methods: {
        onSelected(form) {
            this.$router.push({ name: 'files.submit_form.create', params: { form_id: form.id } })
        },
    },
}
</script>
