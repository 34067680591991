<template>
    <Dropdown icon>
        <template #toggle>
            <i class="fas fa-globe mr-2"></i>
            <span>{{ locales[currentLocale] }}</span>
        </template>

        <div class="menu-item" v-for="(lang, code) in locales" :class="{ active: currentLocale === code }">
            <a href="#" @click.prevent="setLanguage(code)">{{ lang }}</a>
        </div>
    </Dropdown>
</template>

<script>
import { setI18nLanguage } from '~/plugins/i18n.js'

export default {
    props: { save: Boolean },
    data: () => ({ locales: window.config.locales }),

    computed: {
        currentLocale() {
            return this.$i18n.locale
        },
    },

    methods: {
        setLanguage(language) {
            setI18nLanguage(language)
            this.$store.commit('UPDATE_LANGUAGE', { language })
            if (this.save) this.$store.dispatch('updateSettings', { language })
        },
    },
}
</script>
