<template>
    <WatchForUnsavedChanges ref="watch" v-model="countersConfig" v-slot="{ hasChanges, cancel }">
        <ContextBar :title="$t('profile.display')"></ContextBar>

        <select class="form-select w-250" v-model="preferColorScheme" @change="updateColorScheme()">
            <option v-for="scheme in colorSchemes" :value="scheme" :key="scheme">
                {{ $t(`profile.display_${scheme}`) }}
            </option>
        </select>

        <h6>{{ $t('profile.general_settings') }}</h6>

        <label class="form-switch">
            <input v-model="preferences.maps" @change="updatePreferences()" type="checkbox" />
            <i class="form-icon"></i>
            <span>{{ $t(`profile.maps`) }}</span>
        </label>

        <label class="form-switch">
            <input v-model="preferences.counters" @change="updatePreferences() & cancel()" type="checkbox" />
            <i class="form-icon"></i>
            <span>{{ $t(`profile.counters`) }}</span>
        </label>

        <div v-if="preferences.counters">
            <Dropdown icon class="mt-4 p-2">
                <template #toggle>
                    <i class="far fa-timer mr-2"></i>
                    <span>{{ $t(`timeline.filter_date_${preferences.start_date}`) }}</span>
                </template>
                <div class="menu-item" v-for="date in countersDates">
                    <a href="#" @click.prevent="changeCountersDate(date)"
                        :class="{ active: date === preferences.start_date }">
                        {{ $t(`timeline.filter_date_${date}`) }}
                    </a>
                </div>
            </Dropdown>

            <div class="counters-config">
                <table class="table">
                    <tr v-for="item in countersConfig">
                        <td>
                            <div class="d-flex">
                                <select v-model="item.counter" class="form-select" style="flex: 1">
                                    <option value="uploads">{{ $t('profile.counters_uploads') }}</option>
                                    <option value="damages">{{ $t('profile.counters_damages') }}</option>
                                    <option value="files">{{ $t('profile.counters_files') }}</option>
                                    <option value="files_with_damages">
                                        {{ $t('profile.counters_files_with_damages') }}
                                    </option>
                                    <option value="forms">{{ $t('profile.counters_forms') }}</option>
                                    <option value="shares">{{ $t('profile.counters_shares') }}</option>
                                </select>

                                <select v-if="item.counter === 'forms'" v-model="item.params" class="form-select ml-2"
                                    style="flex: 1">
                                    <option :value="undefined">Any</option>
                                    <option v-for="form in forms" :key="form.id" :value="form">{{ form.title }}
                                    </option>
                                </select>
                            </div>
                        </td>
                        <td>
                            <input type="color" v-model="item.color" id="color" class="form-input" />
                        </td>
                        <td>
                            <Dropdown class="dropdown-right">
                                <template #toggle>
                                    <i class="far" :class="item.icon" :style="{ color: item.color }"></i>
                                </template>
                                <div class="menu-item" v-for="icon in faIcons">
                                    <a href="#" @click.prevent="item.icon = icon">
                                        <i class="far fa-fw" :class="icon" :style="{ color: item.color }"></i>
                                    </a>
                                </div>
                            </Dropdown>
                        </td>
                        <td>
                            <button type="button" class="btn btn-clear" @click="removeCounter(item)"></button>
                        </td>
                    </tr>
                    <tr v-if="countersConfig.length <= 5">
                        <td>
                            <button class="btn" @click="addCounter()">
                                <i class="far fa-plus mr-2"></i>
                                <span>{{ $t('profile.add_counter') }}</span>
                            </button>
                        </td>
                    </tr>
                </table>
            </div>

            <div class="context-bar">
                <SaveButton @click="save()" :disabled="!hasChanges" />
                <button class="btn" @click="cancel" :disabled="!hasChanges">{{ $t('main.cancel') }}</button>
            </div>
        </div>
    </WatchForUnsavedChanges>
</template>

<script>
export default {
    data() {
        return {
            forms: [],

            colorSchemes: ['light', 'dark'],
            preferences: this.$store.state.preferences,
            preferColorScheme: this.$store.state.preferColorScheme,
            countersConfig: JSON.parse(JSON.stringify(this.$store.state.countersConfig)),

            countersDates: ['today', 'last_7_days', 'last_30_days', 'all_time'],
            faIcons: ['fa-camera', 'fa-align-left', 'fa-exclamation-circle', 'fa-folder', 'fa-list-alt', 'fa-share', 'fa-truck', 'fa-truck-loading', 'fa-plane', 'fa-ship', 'fa-car-side', 'fa-box', 'fa-boxes', 'fa-pallet', 'fa-clipboard-check', 'fa-shipping-fast', 'fa-warehouse'],
        }
    },

    async mounted() {
        this.$refs.watch?.init()

        const { data: paginator } = await this.$axios.get('forms', {
            params: { limit: 200, sort: 'title', by: 'asc' },
        })

        this.forms = paginator.data.map(({ id, title }) => ({ id, title }))
    },

    methods: {
        updateColorScheme() {
            this.$store.dispatch('updateColorScheme', this.preferColorScheme)
            window.toast(this.$root.$t('profile.settings_updated'))
        },

        updatePreferences() {
            this.$store.dispatch('updateSettings', this.preferences)
            window.toast(this.$root.$t('profile.settings_updated'))
        },

        changeCountersDate(date) {
            this.preferences.start_date = date
            this.updatePreferences()
        },

        save() {
            this.$store.dispatch('updateSettings', { countersConfig: this.countersConfig })
            window.toast(this.$root.$t('profile.settings_updated'))
            this.$refs.watch?.init()
        },

        addCounter() {
            this.countersConfig.push({ counter: 'uploads', icon: 'fa-camera', color: '#1f9f9f' })
        },

        removeCounter(item) {
            this.countersConfig.splice(this.countersConfig.indexOf(item), 1)
        },
    },
}
</script>
