<template>
    <div class="device-version tooltip-bottom" :class="[status, { tooltip: unsupported }]"
        :data-tooltip="versionDescription">
        <i v-if="unsupported" class="fas fa-circle-exclamation mr-1"></i>
        <span>{{ version }}</span>
    </div>
</template>

<script>
export default {
    props: { platform: String, version: String, sideload: Boolean, status: String },

    data() {
        return {        }
    },

    computed: {
        unsupported() {
            return ['unsupported', 'outdated'].includes(this.status)
        },
        versionDescription() {
          return this.status === 'unsupported' ? this.$t('devices.unsupported') : this.$t('devices.outdated')
      }
    }
}
</script>