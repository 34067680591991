<template>
    <WatchForUnsavedChanges ref="watch" v-model="share" v-slot="{ hasChanges }">
        <Modal :title="$t('files.share')" @close="$router.push({ name: 'files.show' })" class="form-horizontal">
            <template #subtitle v-if="file.shares_count">
                <router-link :to="{ name: 'files.share.active' }" class="badge" :data-badge="file.shares_count">
                    <span>{{ $t('main.shares') }}</span>
                </router-link>
            </template>

            <div class="form-group">
                <Errors :errors="errors" />
            </div>

            <div class="form-group">
                <div class="column col-3 col-md-12">
                    <label class="form-label" for="email">{{ $t('share.recipients') }}</label>
                </div>
                <div class="column">
                    <div class="flex-space">
                        <div class="form-autocomplete" style="width: 100%" v-click-outside="hideMenu">
                            <div class="form-autocomplete-input form-input mb-0">
                                <span class="chip" v-for="(email, i) in share.emails">
                                    <span>{{ email }}</span>
                                    <button class="btn btn-sm btn-clear"
                                        @click.prevent="share.emails.splice(i, 1)"></button>
                                </span>

                                <input id="email" type="email" class="form-input" v-model="email"
                                    :placeholder="$t('users.email')" @keyup.enter="addRecipients()"
                                    @blur="addRecipients()" @keyup.backspace="removeLastEmail()" @focus="showMenu()"
                                    autocomplete="off" />
                            </div>
                            <div class="menu" v-if="menuOpen">
                                <div class="menu-item" v-for="email in filteredLastUsedEmails">
                                    <a href="#" @click.prevent="addRecipients(email)">{{ email }}</a>
                                </div>
                            </div>
                        </div>

                        <Dropdown class="dropdown-right ml-2 tooltip tooltip-left"
                            :data-tooltip="$t('main.distribution_lists')" @mouseover.once="fetchContactLists()">
                            <template #toggle><i class="fas fa-at"></i></template>
                            <div class="menu-item" v-for="list in contactLists">
                                <a href="#" @click.prevent="addContactList(list)">{{ list.name }}</a>
                            </div>
                        </Dropdown>
                    </div>
                </div>
            </div>

            <div class="form-group">
                <div class="column col-3 col-md-12">
                    <label class="form-label" for="subject">{{ $t('share.sub') }}</label>
                </div>
                <div class="column">
                    <div class="d-flex">
                        <input id="subject" class="form-input" v-model="share.subject" :placeholder="$t('share.sub')" />
                        <Dropdown class="dropdown-right ml-2">
                            <template #toggle><i class="fas fa-globe"></i></template>
                            <div class="menu-item" v-for="(lang, code) in locales">
                                <a href="#" @click.prevent="share.language = code"
                                    :class="{ active: share.language === code }">
                                    <span>{{ lang }}</span>
                                </a>
                            </div>
                        </Dropdown>
                    </div>
                </div>
            </div>

            <div class="form-group">
                <div class="column col-3 col-md-12">
                    <label class="form-label" for="customer_reference">{{ $t('share.customer_reference') }}</label>
                </div>
                <div class="column">
                    <input id="customer_reference" class="form-input" v-model="share.customer_reference"
                        :placeholder="$t('share.customer_reference')" />
                </div>
            </div>

            <div class="form-group">
                <div class="column col-3 col-md-12">
                    <label class="form-label" for="comment">{{ $t('share.comment') }}</label>
                </div>
                <div class="column">
                    <textarea id="comment" v-model="share.comment" rows="5" class="form-input"></textarea>
                </div>
            </div>

            <div class="form-group">
                <div class="column col-3 col-md-12">
                    <label class="form-label" for="send_me_a_cc">{{ $t('share.send_me_a_cc') }}</label>
                </div>
                <div class="column">
                    <label class="form-checkbox">
                        <input id="send_me_a_cc" v-model="share.send_cc" type="checkbox" />
                        <i class="form-icon"></i>
                    </label>
                </div>
            </div>

            <div class="form-group">
                <div class="column col-3 col-md-12">
                    <label class="form-label" for="allow_zip_download">{{ $t('share.allow_zip_download') }}</label>
                </div>
                <div class="column">
                    <label class="form-checkbox">
                        <input id="allow_zip_download" v-model="share.allow_zip_download" type="checkbox" />
                        <i class="form-icon"></i>
                    </label>
                </div>
            </div>

            <div class="form-group">
                <div class="column col-3 col-md-12">
                    <label class="form-label" for="allow_pdf_download">{{ $t('share.allow_pdf_download') }}</label>
                </div>
                <div class="column">
                    <div class="input-group">
                        <label class="form-checkbox">
                            <input type="checkbox" id="allow_pdf_download" v-model="share.allow_pdf_download"
                                @change="loadReportTemplates()" />
                            <i class="form-icon"></i>
                        </label>
                        <select v-if="share.allow_pdf_download" v-model="share.allow_pdf_download_layout"
                            class="form-select">
                            <option :value="null" disabled hidden>{{ $t('reports.layout') }}</option>
                            <option v-for="template in templates" :value="template.code">{{ template.name }}
                            </option>
                        </select>
                    </div>
                </div>
            </div>

            <div class="form-group">
                <div class="column col-3 col-md-12">
                    <label class="form-label">{{ $t('main.expiry') }}</label>
                </div>
                <div class="column">
                    <DatePicker v-model="share.expiry" :placeholder="$t('main.no_expiry')"></DatePicker>
                </div>
            </div>

            <template #footer>
                <SaveButton @click="createShare()" :disabled="!hasChanges">{{ $t('files.share') }}</SaveButton>
            </template>
        </Modal>
    </WatchForUnsavedChanges>
</template>

<script>
import { loadLocaleMessages } from '~/plugins/i18n.js'

export default {
    props: { file: Object },
    emits: ['refresh'],

    data() {
        return {
            errors: null,
            menuOpen: false,
            lastUsedEmails: [],
            locales: window.config.locales,
            removeEmailOnNextBackspace: true,

            email: '',
            templates: [],
            contactLists: [],

            shareDefaults: this.$store.state.user.location || {},

            share: {
                emails: [],
                subject: '',
                comment: '',
                expiry: '',
                send_cc: false,
                customer_reference: null,
                allow_zip_download: false,
                allow_pdf_download: false,
                allow_pdf_download_layout: null,
                language: this.$store.state.language,
            },
        }
    },

    computed: {
        filteredLastUsedEmails() {
            return this.lastUsedEmails.filter(e => e.includes(this.email)).filter(e => !this.share.emails.includes(e))
        },
    },

    watch: { 'share.language': 'translateSubject' },

    async mounted() {
        if (this.share.allow_pdf_download) this.loadReportTemplates()
        this.share.customer_reference = this.buildCustomerReferenceFromFields()
        this.share.allow_zip_download = this.shareDefaults.share_allow_zip_download || false
        this.share.allow_pdf_download = this.shareDefaults.share_allow_pdf_download || false

        this.share.allow_pdf_download_layout =
            this.shareDefaults.share_allow_pdf_download_layout ||
            this.$store.state.lastReportCreatedValues.template ||
            '1'

        await this.translateSubject()
        this.$refs.watch?.init()
    },

    methods: {
        async createShare() {
            this.errors = null

            try {
                await this.$axios.post(`files/${this.file.id}/shares`, this.share)

                this.$refs.watch?.init()
                this.$router.push({ name: 'files.show' })
                window.toast(this.$root.$t('i.shared', { i: this.file.scan_code }))
                this.$emit('refresh')
            } catch ({ response }) {
                this.errors = response
            }
        },

        async loadReportTemplates() {
            if (this.templates.length) return

            const { data } = await this.$axios.get('report_templates')
            this.templates = data
        },

        async fetchContactLists() {
            const { data: paginator } = await this.$axios.get('distribution_lists', { params: { limit: 1000 } })

            this.contactLists = paginator.data
        },

        async showMenu() {
            this.menuOpen = true

            if (this.lastUsedEmails.length) return

            const { data } = await this.$axios.get('shares/last_used_emails')
            const array = this.extractEmailsFromText(data.toString())
            this.lastUsedEmails = [...new Set(array)]
        },

        hideMenu() {
            this.menuOpen = false
        },

        async translateSubject() {
            const mask = this.shareDefaults.share_subject_mask

            if (mask) {
                this.share.subject = this.buildSubjectByMask(mask)

                return
            }

            const locale = this.share.language.slice(-2).toLowerCase()
            await loadLocaleMessages(locale)

            this.share.subject = this.$root.$t('share.subject', {
                shared_by: this.$store.state.user.name,
                scan_code: this.file.scan_code,
            }, { locale })
        },

        buildCustomerReferenceFromFields() {
            const fieldId = this.shareDefaults.share_customer_reference_field_id
            const fields = this.file.fields.filter(field => field.tag_id === fieldId)
            const field = fields.map(i => i.value).join(',')

            return field
        },

        buildSubjectByMask(mask) {
            // NOTE! this is building the mask in the UI. There is also a PHP function, with this name,
            // doing the same but then for the backend!!!
            let str = mask

            str = str.replace('{{reference}}', this.file.scan_code)
            str = str.replace('{{name}}', this.$store.state.user.name)
            str = str.replace('{{email}}', this.$store.state.user.email)
            str = str.replace('{{company}}', this.$store.state.user.company.company)

            // match and get field value for mask
            str = str.replace(/{{field:(.*?)}}/g, (match, contents) => {
                const tag_id = parseInt(contents)

                const field = this.file.fields.find(f => f.tag_id === tag_id)

                return field ? field.value : ''
            })

            const submits = this.file.form_submits

            str = str.replace(/{{formfield:(.*?)}}/g, (match, contents) => {
                const form_field_id = parseInt(contents)

                for (let i = 0; i < submits.length; i++) {
                    const answers = submits[i].answers

                    for (let j = answers.length - 1; j >= 0; j--) {
                        const answer = answers[j]

                        if (answer.form_field_id === form_field_id && answer.value) {
                            return answer.value
                        }
                    }
                }

                return ''
            })

            return str
        },

        extractEmailsFromText(text) {
            return text.match(/([a-zA-Z0-9+._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi) || []
        },

        addRecipients(text) {
            const emails = this.extractEmailsFromText(text || this.email)

            if (!emails.length) return

            // add new emails to the list, but remove duplicates
            this.share.emails = [...new Set(this.share.emails.concat(emails))]
            this.email = ''

            this.hideMenu()
        },

        removeLastEmail() {
            if (this.email) {
                return this.removeEmailOnNextBackspace = false
            }

            if (this.removeEmailOnNextBackspace) {
                return this.share.emails.pop()
            }

            this.removeEmailOnNextBackspace = true
        },

        addContactList(list) {
            this.addRecipients(list.recipients.map(author => author.email).join(','))
        },
    },
}
</script>
