<template>
    <Dropdown class="file-search-dropdown dropdown-right dropdown-big" @open="focusSearch()">
        <template #toggle>
            <slot><i class="far fa-magnifying-glass fa-fw"></i></slot>
        </template>
        <div class="nav">
            <DebounceInput v-model="search" ref="search" clearable searchIcon @keyup.enter="$refs.results?.click()"
                :placeholder="$t('files.search_on_reference')" :tabindex="10" :loading="loading" />

            <div v-if="!onSelectFile && files.length" class="search-header flex-space">
                <div class="text-bold h5">{{ $t('table.show_results', files.length) }}</div>
                <a href="#" ref="results" @click.prevent="showAllResults()">{{ $t('table.show_all_results') }}</a>
            </div>

            <div class="search-result">
                <div v-if="files.length" class="result-list">
                    <a v-if="onSelectFile" v-for="(file, index) in nonSelectedFiles" :tabindex="index + 10"
                        class="result-list-item flex-space" href="#" @click.prevent="$emit('selectFile', file)">
                        <div>
                            <div class="text-clamped">{{ file.scan_code }}</div>
                            <DateTime :date="file.created_at" ago class="text-gray"></DateTime>
                        </div>
                    </a>

                    <router-link v-else v-for="(file, index) in files" :tabindex="index + 10"
                        class="result-list-item flex-space" :to="{ name: 'files.show', params: { id: file.id } }">
                        <div>
                            <div class="text-clamped">{{ file.scan_code }}</div>
                            <DateTime :date="file.created_at" ago class="text-gray"></DateTime>
                        </div>

                        <div class="result-list-actions">
                            <a v-if="file.closed_at" href="#">
                                <i class="fas fa-lock fa-fw"></i>
                            </a>
                            <router-link :to="{ name: 'files.share.create', params: { id: file.id } }">
                                <i class="far fa-share-nodes fa-fw"></i>
                            </router-link>
                        </div>
                    </router-link>
                </div>

                <div v-else-if="!search || loading" class="text-center">
                    <figure class="avatar avatar-lg bg-linen text-dark mb-4">
                        <i class="far fa-magnifying-glass fa-xs"></i>
                    </figure>
                    <h5 class="text-bold mb-1">{{ $t('files.search_for_files') }}</h5>
                    <p class="text-medium text-gray">{{ $t('files.begin_typing_to_search') }}</p>
                </div>

                <div v-else class="text-center">
                    <figure class="avatar avatar-lg bg-linen text-dark mb-4">
                        <i class="fas fa-box-open fa-xs"></i>
                    </figure>
                    <h5 class="text-bold mb-1">{{ $t('files.no_files_found') }}</h5>
                    <p class="text-medium text-gray">{{ $t('files.try_a_different_reference') }}</p>
                </div>
            </div>
        </div>
    </Dropdown>
</template>

<script>
export default {
    props: {
        selectedFiles: Array,
        onSelectFile: Function,
    },

    data() {
        return {
            files: [],
            search: null,
            loading: false,
        }
    },

    computed: {
        nonSelectedFiles() {
            return this.files.filter(file => !this.selectedFiles.includes(file.id))
        }
    },

    watch: {
        search(search) {
            if (search) {
                this.fetchData()
            } else {
                this.files = []
            }
        }
    },

    methods: {
        async fetchData() {
            // This ensures the loading state for slow connections
            if (this.loading) setTimeout(() => this.loading = true)

            this.loading = true

            try {
                const { data } = await this.$axios.get('search', { params: { query: this.search } })
                this.files = data
            } catch { }

            this.loading = false
        },

        showAllResults() {
            if (this.onSelectFile) return

            this.$router.push({ name: 'files', query: { search: this.search, rd: false } })
        },

        focusSearch() {
            setTimeout(() => this.$refs.search?.clear())
        },
    },
}
</script>
