<template>
    <div>
        <ContextBar :title="$t('main.api_tokens')" :buttonText="$t('context.create_api_tokens')"
            buttonAbility="Use global settings" buttonRoute="settings.api_tokens.create"></ContextBar>

        <DataTable endpoint="api_tokens" :columns="columns" :key="refresh">
            <template #context-left>
                <span class="mr-2">{{ $t('settings.api_tokens_to_consume_data') }}</span>
                <a href="https://developer.cargosnap.com" target="_blank">{{ $t('api_tokens.documentation') }}</a>
            </template>
            <template #column(created_at)="{ value }">
                <DateTime :date="value"></DateTime>
            </template>
            <template #column(expiry_date)="{ value }">
                <DateTime v-if="value" :date="value"></DateTime>
                <span v-else>∞</span>
            </template>
            <template #column(description)="{ value }">{{ value }}</template>
            <template #column(token)="{ value }">
                <button class="btn" @click="copyTokenToClipboard(value)">
                    <i class="fas fa-copy mr-2"></i>
                    <span>{{ $t('api_tokens.copy_clipboard') }}</span>
                </button>
            </template>
            <template #column(actions)="{ item }">
                <Dropdown class="dropdown-right">
                    <div class="menu-item text-error">
                        <a href="#" @click.prevent="deleteToken(item)">{{ $t('main.delete') }}</a>
                    </div>
                </Dropdown>
            </template>
        </DataTable>

        <router-view @refresh="refresh++"></router-view>
    </div>
</template>

<script>
export default {
    data() {
        return {
            refresh: 0,

            columns: [
                { name: 'created_at', th: 'main.created', sortable: true },
                { name: 'expiry_date', th: 'main.expiry', sortable: true },
                { name: 'description', th: 'api_tokens.system', sortable: true },
                { name: 'token', th: 'api_tokens.token' },
                { name: 'actions', min: true },
            ],
        }
    },

    methods: {
        copyTokenToClipboard(token) {
            navigator.clipboard.writeText(token)
            window.toast(this.$root.$t('api_tokens.token_copied_to_clipboard'))
        },

        deleteToken(token) {
            this.$swal({
                showCancelButton: true,
                title: this.$t('main.are_you_sure'),
                text: this.$t('main.you_wont_be_able_to_revert_this'),
                confirmButtonText: this.$t('main.yes_delete_it'),
                cancelButtonText: this.$t('main.cancel'),
            }).then(result => {
                if (result.value) {
                    this.$axios.delete(`api_tokens/${token.id}`).then(() => this.refresh++)
                }
            })
        },
    },
}
</script>
