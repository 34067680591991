<script setup>
import i18n from '~/plugins/i18n.js'
import { useVuelidate } from '@vuelidate/core'
import { required, minLength, helpers } from '@vuelidate/validators'

const model = defineModel()
const props = defineProps({ label: Boolean, placeholder: String })

const passwordRules = (password) => {
    if (false === /\d/.test(password)) return false // Minimum of 1 Number
    if (false === /[A-Z]/.test(password)) return false // Minimum of 1 Uppercase Letter

    return true
}

const rules = {
    required: helpers.withMessage('', required),
    minLength: helpers.withMessage(i18n.global.t('auth.new_password_minlength', { minlength: 8 }), minLength(8)),
    passwordRules: helpers.withMessage(i18n.global.t('auth.new_password_complexity'), passwordRules),
}

const v$ = useVuelidate(rules, model)
</script>

<template>
    <div class="form-group" :class="{ 'has-error': v$.$errors.length }">
        <label v-if="label" class="form-label" for="password">{{ $t('users.password') }}</label>

        <input type="password" id="password" v-model="model" class="form-input" autocomplete="new-password"
            :placeholder="props.placeholder" />

        <div v-for="e in v$.$errors" class="form-input-hint">{{ e.$message }}</div>
    </div>
</template>