<template>
    <form @submit.prevent="submit()">
        <div class="form-group">
            <h2>{{ $t('auth.get_started_today') }}</h2>
        </div>

        <div class="form-group">
            <Errors :errors="errors" />
        </div>

        <div class="form-group">
            <label class="form-label" for="name">{{ $t('users.name') }}</label>
            <input v-model="invitation.name" id="name" class="form-input" readonly />
        </div>

        <div class="form-group">
            <label class="form-label" for="email">{{ $t('users.email') }}</label>
            <input v-model="invitation.email" id="email" class="form-input" readonly />
        </div>

        <PasswordInput v-model="invitation.password" label></PasswordInput>

        <div class="form-group d-flex">
            <label class="form-checkbox">
                <input id="tos" type="checkbox" v-model="TOSAccepted" />
                <i class="form-icon"></i>
            </label>
            <label for="tos" v-html="tos"></label>
        </div>

        <div class="form-group">
            <button type="submit" class="btn btn-primary" :disabled="!TOSAccepted" :class="{ loading }">
                <span>{{ $t('auth.create_account') }}</span>
            </button>
        </div>
    </form>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import PasswordInput from '~/composition/PasswordInput.vue'

export default {
    setup: () => ({ v$: useVuelidate() }),

    components: { PasswordInput },

    data() {
        return {
            errors: null,
            loading: false,

            tos: null,
            TOSAccepted: false,

            invitation: {},
            token: this.$route.params.token,
        }
    },

    async mounted() {
        const terms = this.$t('auth.terms')
        const privacy = this.$t('auth.privacy')
        const cookie_use = this.$t('auth.cookie_use')

        this.tos = this.$t('auth.tos', {
            terms: `<a href='https://www.cargosnap.com/terms-of-use' target='_blank'>${terms}</a>`,
            privacy: `<a href='https://www.cargosnap.com/privacy-terms' target='_blank'>${privacy}</a>`,
            cookie_use: `<a href='https://www.cargosnap.com/cookie-use-terms' target='_blank'>${cookie_use}</a>`,
        })

        try {
            const { data } = await this.$axios.get(`auth/invited/${this.token}`)

            this.invalid = false
            this.invitation = data
        } catch ({ response }) {
            this.errors = response
        }
    },

    methods: {
        async submit() {
            if (!await this.v$.$validate()) return

            this.errors = null
            this.loading = true

            try {
                await this.$axios.post(`auth/invited/${this.token}`, this.invitation)
                const { data } = await this.$axios.post('auth/login', { email: this.invitation.email, password: this.invitation.password })

                this.$store.dispatch('startSession', data)
                this.$router.push({ name: 'dashboard' })
                window.toast(this.$root.$t('auth.welcome'))

            } catch ({ response }) {
                this.errors = response
            }

            this.loading = false
        },
    },
}
</script>
