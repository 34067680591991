<script setup>
import { ref } from 'vue'

const open = ref(false)
const props = defineProps({ icon: Boolean, badge: Number })
const emit = defineEmits(['open', 'close'])

const hideMenu = () => {
    setTimeout(() => {
        open.value = false
        emit('close')
    })
}

const toggleMenu = () => {
    open.value = !open.value

    if (open.value) emit('open')
}

const onClickMenu = e => {
    // Hide menu when clicking on a link
    if (e.target.closest('a')) hideMenu()
}
</script>

<template>
    <div class="dropdown" :class="{ 'active': open }">
        <button type="button" class="btn" :class="{ badge }" :data-badge="badge" @click="toggleMenu()">
            <slot name="toggle">
                <i class="fas fa-ellipsis-h fa-fw"></i>
            </slot>
            <i v-if="icon" class="fas ml-2" :class="open ? 'fa-caret-up' : 'fa-caret-down'"></i>
        </button>

        <div v-if="open" class="menu" @click="onClickMenu" v-click-outside="hideMenu">
            <slot></slot>
        </div>
    </div>
</template>