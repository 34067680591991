<template>
    <div>
        <ContextBar :title="$t('main.forms')" :buttonText="$t('context.create_forms')"
            buttonAbility="Use global settings" buttonRoute="forms.create">
            <router-link exact tag="button" class="btn btn-order" :to="{ name: 'forms.order' }"
                v-if="$store.getters.can('Use global settings')">
                <i class="far fa-mobile mr-2"></i>
                <span>{{ $t('devices.change_order_for_forms_in_app') }}</span>
            </router-link>
            <DebounceInput v-model="search" clearable searchIcon />
        </ContextBar>

        <DataTable endpoint="forms" :columns="columns" :parameters="parameters" sort="submit_date_time" :key="refresh">
            <template #column(title)="{ item }">
                <router-link :to="{ name: 'forms.show', params: { id: item.id } }" class="table-link">
                    {{ item.title }}
                </router-link>
            </template>
            <template #column(submits_count)="{ item }">
                <router-link :to="{ name: 'forms.show', params: { id: item.id } }">
                    <div class="chip chip-lg">
                        <i class="far fa-circle-check"></i>
                        <span class="ml-2">{{ item.submits_count }}</span>
                    </div>
                </router-link>
            </template>
            <template #column(submit_date_time)="{ value }">
                <DateTime :date="value"></DateTime>
            </template>
            <template #column(expand)="{ item }">
                <button class="btn" @click="onExpandRow(item)">
                    <i class="far fa-fw" :class="[item.expanded ? 'fa-chevron-up' : 'fa-chevron-down']"></i>
                </button>
            </template>
            <template #column(actions)="{ item }">
                <Dropdown class="dropdown-right" v-if="$store.getters.can('Use global settings')">
                    <div class="menu-item">
                        <router-link :to="{ name: 'forms.edit', params: { form_id: item.id } }">
                            {{ $t('main.edit') }}
                        </router-link>
                    </div>
                    <div class="menu-item">
                        <a href="#" @click.prevent="duplicateForm(item)">{{ $t('main.duplicate') }}</a>
                    </div>
                    <div class="menu-item">
                        <a href="#" class="text-error" @click.prevent="deleteForm(item)">{{ $t('main.delete') }}</a>
                    </div>
                </Dropdown>
            </template>

            <template #expanded="{ item }">
                <div class="workflows-table-step bg-gray" v-for="(field, i) in item.fields" :key="field.id">
                    <figure class="avatar" :data-initial="i + 1"></figure>
                    <div class="text-bold">{{ field.label }}{{ field.required ? '*' : '' }}</div>
                </div>
            </template>
        </DataTable>

        <router-view @refresh="refresh++"></router-view>
    </div>
</template>

<script>
import { duplicateForm, deleteForm } from '~/helpers/forms.js'

export default {
    data() {
        return {
            refresh: 0,
            search: this.$route.query.search,

            columns: [
                { name: 'title', th: 'main.title', sortable: true, class: 'mw-250' },
                { name: 'submits_count', th: 'forms.submits', sortable: true },
                { name: 'submit_date_time', th: 'forms.recent_submit', sortable: true },
                { name: 'expand', min: true },
                { name: 'actions', min: true },
            ],
        }
    },

    computed: {
        parameters() {
            return {
                counts: true,
                search: this.search || undefined,
            }
        },
    },

    methods: {
        async onExpandRow(item) {
            item.expanded = !item.expanded

            if (!item.loaded) {
                const { data: form } = await this.$axios.get(`forms/${item.id}`)
                Object.assign(item, form)
                item.loaded = true
            }
        },

        async deleteForm(form) {
            await deleteForm(form, this.$axios, this.$swal)

            window.toast(this.$root.$t('i.delete', { i: form.title }))

            this.refresh++
        },

        async duplicateForm(form) {
            const { data } = await duplicateForm(form, this.$axios, this.$swal)

            this.$router.push({ name: 'forms.edit', params: { form_id: data.id } })
            window.toast(this.$root.$t('i.create', { i: data.title }))
            this.refresh++
        },
    },
}
</script>
