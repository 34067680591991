<template>
    <div>
        <ContextBar :title="$t('main.distribution_lists')" :buttonText="$t('context.create_distribution_lists')"
            buttonAbility="Create distribution lists" buttonRoute="settings.contact_lists.create">
            <DebounceInput v-model="search" clearable searchIcon />
        </ContextBar>

        <DataTable endpoint="distribution_lists" :columns="columns" :parameters="parameters" sort="name" by="asc"
            :key="refresh">
            <template #column(name)="{ item }">
                <router-link :to="{ name: 'settings.contact_lists.show', params: { id: item.id } }" class="table-link">
                    <span>{{ item.name }}</span>
                </router-link>
            </template>
            <template #column(recipients)="{ value }">
                <div class="chip" v-for="recipient in value" :key="recipient.id">
                    <span>{{ recipient.email }}</span>
                    <span v-if="recipient.label" class="text-small">{{ recipient.label }}</span>
                </div>
            </template>
            <template #column(customer_id)="{ value }">{{ value }}</template>
            <template #column(language)="{ value }">{{ locales[value] }}</template>
            <template #column(actions)="{ item }">
                <Dropdown class="dropdown-right">
                    <div class="menu-item">
                        <router-link :to="{ name: 'settings.contact_lists.show', params: { id: item.id } }">
                            {{ $t('main.edit') }}
                        </router-link>
                    </div>
                    <div class="menu-item">
                        <a href="#" class="text-error" @click.prevent="deleteList(item)">{{ $t('main.delete') }}</a>
                    </div>
                </Dropdown>
            </template>
        </DataTable>

        <router-view @refresh="refresh++"></router-view>
    </div>
</template>

<script>
export default {
    data() {
        return {
            refresh: 0,
            search: this.$route.query.search,
            locales: window.config.locales,

            columns: [
                { name: 'name', th: 'distribution_lists.name', sortable: true },
                { name: 'recipients', th: 'share.recipients' },
                { name: 'customer_id', th: 'distribution_lists.customer_id', sortable: true },
                { name: 'language', th: 'main.language', sortable: true },
                { name: 'actions', min: true },
            ],
        }
    },

    computed: {
        parameters() {
            return {
                search: this.search || undefined,
            }
        },
    },

    methods: {
        deleteList(item) {
            this.$swal({
                showCancelButton: true,
                title: this.$t('main.are_you_sure'),
                text: this.$t('main.you_wont_be_able_to_revert_this'),
                confirmButtonText: this.$t('main.yes_delete_it'),
                cancelButtonText: this.$t('main.cancel'),
            }).then(result => {
                if (result.value) {
                    this.$axios.delete(`distribution_lists/${item.id}`).then(() => {
                        window.toast(this.$root.$t('i.delete', { i: item.name }))
                        this.refresh++
                    })
                }
            })
        },
    },
}
</script>
